@import url('https://fonts.googleapis.com/css?family=Ubuntu&display=swap');

body{
  font-family: 'Ubuntu', sans-serif !important;
  min-width: 320px!important;
  overflow-x: hidden;
}

.loader-element {
  animation: logo-spin infinite 2s cubic-bezier(0, 1.03, 1, 2.03);
  transition: all 1s fade-in;
  pointer-events: none;
  width:300px;
  height:300px;
  background-position: center;
  margin-top:25vh;
}
.container-fluid{
  padding: 0 !important;
}



@keyframes logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
