.stopka{
  height: 300px auto;
  border-top: 2px solid white;
  padding-top: 50px;
  text-align: justify;
  padding-left: 3%;
  background: #7a7676;
  /*border-bottom: 0.5px solid black;
  border-radius: 2px;*/
}
.card-footer{
  padding-top: 50px;
}
.stopka2{
  padding-top: 20px;
  text-align: center;
}
ul{
  padding-left: 1px;
}
a:link{
  text-decoration: none;
  color: black;
}
.card1 h5{
  padding-top: 10px;
}
.element-listy{
  list-style-type: none;
}
.card-footer h5{
  transition: all .5s;
}
.card-footer h5:hover{
  color: white;
}
.Icon{
  width: 50px;
  height: 50px;
}
