/**/

.t1{
width:100%;
height: 40vh;
padding: 0 20px 0 20px;
}
.firma-tytul {
  padding-top: 4vh;
  color: white;
  text-align: left;
  padding-left: 4%;
  /*background: url(https://www.iliketowastemytime.com/sites/default/files/night-sky-hd-wallpaper.jpg) no-repeat top center ;*/
  background: url(https://images8.alphacoders.com/659/659526.jpg) no-repeat center;
  background-size: cover;
}

.firma-tytul a{
  color: black !important;
  text-decoration: none !important;
  text-decoration-color: none !important;
  text-align:center;
  padding:15px 0;
}
/*----------CONTENT--BLOKI-----------*/
.blok-content{
  color:black;
  border-left: 2px solid #ccc;
  font-size:1.1rem;
  padding:1em;
  text-align: justify;
  animation-name: pojawianie;
  animation-duration: 4s;

}
/*----------TYTUŁ--GŁÓWNY------------*/
.firma-tytul h1{
  text-align:center;
  padding:15px 0;

  animation-name:szerokosc;
  animation-duration:1s;
  animation-fill-mode: both;
  /*animation-iteration-count:infinite;*/

  -webkit-animation-name:szerokosc;
  -webkit-animation-duration:1s;
  /*-webkit-animation-iteration-count:infinite;*/
}
/*---------NAV--PARAGRAPH------------*/
.firma-tytul p{
  color: black;
}
/*--------------NAV------------------*/
.firma-nav{
  background: white !important;
  width: 20%;
  text-align: center;
  animation-name: szerokosc;
}
/*-------------TYTUŁY--CONTENT-----------------*/
.about-header{
  animation-name:e1;
  animation-duration:1s;
  animation-fill-mode: both;
  -webkit-animation-name:e1;
  -webkit-animation-duration:1s;

}
.tytul3{
  animation-name:szerokosc;
  animation-duration:1s;
  animation-fill-mode: both;
  /*animation-iteration-count:infinite;*/
  -webkit-animation-name:szerokosc;
  -webkit-animation-duration:1s;
  /*-webkit-animation-iteration-count:infinite;*/
}
/*-------------STOPKA----------------*/
.dummy{
  height: 20vh;
}
/*----------ANIMACJE--&--RESPONSYWNOŚĆ-------*/
/* ------------ANIMACJA--SZEROKOSCI------------ */
@keyframes szerokosc
{
  0% { width:10%; }
  100% { width:100%; }
}

@-webkit-keyframes szerokosc
{
  0% { width:10%; }
  100% { width:100%; }
}
@keyframes e1
{
  0% { width:300%; }
  100% { width:100%; }
}

@-webkit-keyframes e1
{
  0% { width:300%; }
  100% { width:100%; }
}
/*--------------RESPONSYWNOŚĆ----------------*/
@media screen and (max-width: 700px) {
  .firma-nav {
    display: none;
  }
}
/* ------------ANIMACJA--POJAWIANIA--SIĘ--LITER---------------*/
@keyframes pojawianie {
    from {opacity:0;}
    to {opacity:1;}
}
/*------------ANIMACJA-----------*/
