
.firma-tytul {
  color: white;
  text-align: left;
  background: url(https://www.iliketowastemytime.com/sites/default/files/night-sky-hd-wallpaper.jpg) no-repeat top center ;
  background-size: cover;
}

.card img{
  width: 100px!important;
  height: 100px!important;
}

.card{
  text-align: center;
  width:70%;
  margin: auto;
  margin-top:100px;
  background-color:#d33848;
}

.firma-tytul a{
  text-decoration: none;
  text-align:center;
  padding:15px 0;

}
/*----------CONTENT--BLOKI-----------*/
.blok-content{
  color:black;
  border-left: 2px solid #ccc;
  padding-right: 40px;
  text-align: justify;
  animation-name: pojawianie;
  animation-duration: 4s;
}
/*----------TYTUŁ--GŁÓWNY------------*/
.firma-tytul h1{
  text-align:center;
  padding:15px 0;
  /*border:3px solid #FDD700;*/

  animation-name:szerokosc;
  animation-duration:3s;
  animation-fill-mode: both;
  /*animation-iteration-count:infinite;*/
  -webkit-animation-name:szerokosc;
  -webkit-animation-duration:3s;
  /*-webkit-animation-iteration-count:infinite;*/
}
/*---------NAV--PARAGRAPH------------*/
.firma-tytul p{
  color: black;
  text-align: center;
}
/*--------------NAV------------------*/
.firma-nav{
  background: white !important;
  width: 10%;
  border-radius: 5px;
  text-align: center;
  -webkit-box-shadow: -30px 31px 13px -13px rgba(0,0,0,0.62);
  -moz-box-shadow: -30px 31px 13px -13px rgba(0,0,0,0.62);
  box-shadow: -30px 31px 13px -13px rgba(0,0,0,0.62);
  padding:15px 0;
  padding-bottom: 1px;
  animation-name: szerokosc;
  animation-duration: 1s;
  animation-fill-mode: both;
  -webkit-animation-name:szerokosc;
  -webkit-animation-duration:1s;
}
.firma-nav a{
  transition: all ease-in 1s;
}
.firma-nav a:hover{
  color: blue;
}
/*-------------TYTUŁY--CONTENT-----------------*/
.tytul2{
  animation-name: szerokosc;
}
.image{
width:100%;
height:auto;
padding:1em;
overflow:hidden;
}

/*-------------STOPKA----------------*/
.dummy{
  padding-top: 10vh;
}
/*----------ANIMACJE--&--RESPONSYWNOŚĆ-------*/
/* ------------ANIMACJA--SZEROKOSCI------------ */
@keyframes szerokosc
{
  0% { width:0% }
  100% { width:30%; }
}


@-webkit-keyframes szerokosc
{
  0% { width:100px; }
  100% { width:30%; }
}
@keyframes szerokosc1
{
  0% { width:150px; }
  100% { width:100%; }
}

@-webkit-keyframes szerokosc1
{
  0% { width:100px; }
  100% { width:100%; }
}
/*--------------RESPONSYWNOŚĆ----------------*/
@media screen and (max-width: 700px) {
  .firma-nav {
    display: none;
  }
}
/* ------------ANIMACJA--POJAWIANIA--SIĘ--LITER---------------*/
@keyframes pojawianie {
    from {opacity:0;}
    to {opacity:1;}
}


.offerWrapper h1{
letter-spacing: .1em;
font-size: calc(2vw + 1em);
}
.offerWrapper h2{
  letter-spacing: .1em;
  font-size: calc(1vw + 1em);
}
.offerWrapper ul{
  padding:calc(1vw+1em)!important;
  text-align: center;
  list-style-type: none;
}
.offerWrapper ul li {
  display:block;
  padding:1em;
  transition:all ease-in-out .6s;
  background-color: rgba(224, 158, 73, 0);
}
.offerWrapper ul li:hover{
  background-color: rgb(224, 158, 73);
  border-radius:2px;
  cursor:pointer;
  box-shadow: 2px 3px 6px -6px #8a8132;
  transition: all ease-in-out;
  transform: rotate3d(0,1,0,2deg);

}
.offerWrapper ul li:before{
  position: absolute;
  content:'»';
  transform:translateX(-100px);
  opacity: 0;
  size:1.5em;
}
.offerWrapper ul li:hover:before{
  transition: all ease-in-out .7s;
  opacity: 1;
  transform:translateX(-1em);
}
.offerWrapper img{
  padding:1em;
}

.spaceTop{
  padding-top:3em;
}
