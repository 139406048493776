.content {
color:black;
padding-right: 40px;
text-align: justify;
animation-name: pojawianie;
animation-duration: 3s;
}
.map {
  animation-name: pojawianie;
  animation-duration: 3s;
}
.formularz{
  padding: 0px 30px 40px 0px;
}
@media (max-width: 768px) {
.formularz{
  padding: 20px 30px 40px 30px;
}
.logo {
  padding-left: 20px;
  padding-right: 20px;
}
}
@keyframes szerokosc
{
  0% { width:10%; }
  100% { width:100%; }
}

@-webkit-keyframes szerokosc
{
  0% { width:10%; }
  100% { width:100%; }
}
@keyframes e1
{
  0% { width:300%; }
  100% { width:100%; }
}

@-webkit-keyframes e1
{
  0% { width:300%; }
  100% { width:100%; }
}
@keyframes pojawianie {
    from {opacity:0;}
    to {opacity:1;}
}
