@import url('https://fonts.googleapis.com/css?family=Titillium+Web&display=swap');
.info {
  background-color: #b4b4b4;
  font-family: 'Titillium Web', sans-serif;
  height:40px;
  padding-left: 110px !important;
  font-size:1rem;
  text-align:center;
  padding:.4rem;
  overflow: visible;
}
.info span{
  border-left: 1px solid black !important;
  padding: 0 10px 0 0;
  display: block;
}
@media screen and (max-width:1206px)
{
  .info{
    display:none;
  }
}
.info a{
  text-decoration: none !important;
  color: black !important;
  transition: all .5s;
}
.info a:hover{
  color: white !important;
}
