@import url(https://fonts.googleapis.com/css?family=Ubuntu&display=swap);
@import url(https://fonts.googleapis.com/css?family=Titillium+Web&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
.no-gutter > [class*='col-'] {
    padding-right:0;
    padding-left:0;
}

body{
  font-family: 'Ubuntu', sans-serif !important;
  min-width: 320px!important;
  overflow-x: hidden;
}

.loader-element {
  -webkit-animation: logo-spin infinite 2s cubic-bezier(0, 1.03, 1, 2.03);
          animation: logo-spin infinite 2s cubic-bezier(0, 1.03, 1, 2.03);
  transition: all 1s fade-in;
  pointer-events: none;
  width:300px;
  height:300px;
  background-position: center;
  margin-top:25vh;
}
.container-fluid{
  padding: 0 !important;
}



@-webkit-keyframes logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}



@keyframes logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.info {
  background-color: #b4b4b4;
  font-family: 'Titillium Web', sans-serif;
  height:40px;
  padding-left: 110px !important;
  font-size:1rem;
  text-align:center;
  padding:.4rem;
  overflow: visible;
}
.info span{
  border-left: 1px solid black !important;
  padding: 0 10px 0 0;
  display: block;
}
@media screen and (max-width:1206px)
{
  .info{
    display:none;
  }
}
.info a{
  text-decoration: none !important;
  color: black !important;
  transition: all .5s;
}
.info a:hover{
  color: white !important;
}

.content {
color:black;
padding-right: 40px;
text-align: justify;
-webkit-animation-name: pojawianie;
        animation-name: pojawianie;
-webkit-animation-duration: 3s;
        animation-duration: 3s;
}
.map {
  -webkit-animation-name: pojawianie;
          animation-name: pojawianie;
  -webkit-animation-duration: 3s;
          animation-duration: 3s;
}
.formularz{
  padding: 0px 30px 40px 0px;
}
@media (max-width: 768px) {
.formularz{
  padding: 20px 30px 40px 30px;
}
.logo {
  padding-left: 20px;
  padding-right: 20px;
}
}
@keyframes szerokosc
{
  0% { width:10%; }
  100% { width:100%; }
}

@-webkit-keyframes szerokosc
{
  0% { width:10%; }
  100% { width:100%; }
}
@keyframes e1
{
  0% { width:300%; }
  100% { width:100%; }
}

@-webkit-keyframes e1
{
  0% { width:300%; }
  100% { width:100%; }
}
@-webkit-keyframes pojawianie {
    from {opacity:0;}
    to {opacity:1;}
}
@keyframes pojawianie {
    from {opacity:0;}
    to {opacity:1;}
}


.firma-tytul {
  color: white;
  text-align: left;
  background: url(https://www.iliketowastemytime.com/sites/default/files/night-sky-hd-wallpaper.jpg) no-repeat top center ;
  background-size: cover;
}

.card img{
  width: 100px!important;
  height: 100px!important;
}

.card{
  text-align: center;
  width:70%;
  margin: auto;
  margin-top:100px;
  background-color:#d33848;
}

.firma-tytul a{
  text-decoration: none;
  text-align:center;
  padding:15px 0;

}
/*----------CONTENT--BLOKI-----------*/
.blok-content{
  color:black;
  border-left: 2px solid #ccc;
  padding-right: 40px;
  text-align: justify;
  -webkit-animation-name: pojawianie;
          animation-name: pojawianie;
  -webkit-animation-duration: 4s;
          animation-duration: 4s;
}
/*----------TYTUŁ--GŁÓWNY------------*/
.firma-tytul h1{
  text-align:center;
  padding:15px 0;
  /*border:3px solid #FDD700;*/

  animation-name:szerokosc;
  animation-duration:3s;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
  /*animation-iteration-count:infinite;*/
  -webkit-animation-name:szerokosc;
  -webkit-animation-duration:3s;
  /*-webkit-animation-iteration-count:infinite;*/
}
/*---------NAV--PARAGRAPH------------*/
.firma-tytul p{
  color: black;
  text-align: center;
}
/*--------------NAV------------------*/
.firma-nav{
  background: white !important;
  width: 10%;
  border-radius: 5px;
  text-align: center;
  box-shadow: -30px 31px 13px -13px rgba(0,0,0,0.62);
  padding:15px 0;
  padding-bottom: 1px;
  animation-name: szerokosc;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
  -webkit-animation-name:szerokosc;
  -webkit-animation-duration:1s;
}
.firma-nav a{
  transition: all ease-in 1s;
}
.firma-nav a:hover{
  color: blue;
}
/*-------------TYTUŁY--CONTENT-----------------*/
.tytul2{
  -webkit-animation-name: szerokosc;
          animation-name: szerokosc;
}
.image{
width:100%;
height:auto;
padding:1em;
overflow:hidden;
}

/*-------------STOPKA----------------*/
.dummy{
  padding-top: 10vh;
}
/*----------ANIMACJE--&--RESPONSYWNOŚĆ-------*/
/* ------------ANIMACJA--SZEROKOSCI------------ */
@keyframes szerokosc
{
  0% { width:0% }
  100% { width:30%; }
}


@-webkit-keyframes szerokosc
{
  0% { width:100px; }
  100% { width:30%; }
}
@keyframes szerokosc1
{
  0% { width:150px; }
  100% { width:100%; }
}

@-webkit-keyframes szerokosc1
{
  0% { width:100px; }
  100% { width:100%; }
}
/*--------------RESPONSYWNOŚĆ----------------*/
@media screen and (max-width: 700px) {
  .firma-nav {
    display: none;
  }
}
/* ------------ANIMACJA--POJAWIANIA--SIĘ--LITER---------------*/
@-webkit-keyframes pojawianie {
    from {opacity:0;}
    to {opacity:1;}
}
@keyframes pojawianie {
    from {opacity:0;}
    to {opacity:1;}
}


.offerWrapper h1{
letter-spacing: .1em;
font-size: calc(2vw + 1em);
}
.offerWrapper h2{
  letter-spacing: .1em;
  font-size: calc(1vw + 1em);
}
.offerWrapper ul{
  padding:calc(1vw+1em)!important;
  text-align: center;
  list-style-type: none;
}
.offerWrapper ul li {
  display:block;
  padding:1em;
  transition:all ease-in-out .6s;
  background-color: rgba(224, 158, 73, 0);
}
.offerWrapper ul li:hover{
  background-color: rgb(224, 158, 73);
  border-radius:2px;
  cursor:pointer;
  box-shadow: 2px 3px 6px -6px #8a8132;
  transition: all ease-in-out;
  transform: rotate3d(0,1,0,2deg);

}
.offerWrapper ul li:before{
  position: absolute;
  content:'»';
  transform:translateX(-100px);
  opacity: 0;
  size:1.5em;
}
.offerWrapper ul li:hover:before{
  transition: all ease-in-out .7s;
  opacity: 1;
  transform:translateX(-1em);
}
.offerWrapper img{
  padding:1em;
}

.spaceTop{
  padding-top:3em;
}

/**/

.t1{
width:100%;
height: 40vh;
padding: 0 20px 0 20px;
}
.firma-tytul {
  padding-top: 4vh;
  color: white;
  text-align: left;
  padding-left: 4%;
  /*background: url(https://www.iliketowastemytime.com/sites/default/files/night-sky-hd-wallpaper.jpg) no-repeat top center ;*/
  background: url(https://images8.alphacoders.com/659/659526.jpg) no-repeat center;
  background-size: cover;
}

.firma-tytul a{
  color: black !important;
  text-decoration: none !important;
  -webkit-text-decoration-color: none !important;
          text-decoration-color: none !important;
  text-align:center;
  padding:15px 0;
}
/*----------CONTENT--BLOKI-----------*/
.blok-content{
  color:black;
  border-left: 2px solid #ccc;
  font-size:1.1rem;
  padding:1em;
  text-align: justify;
  -webkit-animation-name: pojawianie;
          animation-name: pojawianie;
  -webkit-animation-duration: 4s;
          animation-duration: 4s;

}
/*----------TYTUŁ--GŁÓWNY------------*/
.firma-tytul h1{
  text-align:center;
  padding:15px 0;

  animation-name:szerokosc;
  animation-duration:1s;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
  /*animation-iteration-count:infinite;*/

  -webkit-animation-name:szerokosc;
  -webkit-animation-duration:1s;
  /*-webkit-animation-iteration-count:infinite;*/
}
/*---------NAV--PARAGRAPH------------*/
.firma-tytul p{
  color: black;
}
/*--------------NAV------------------*/
.firma-nav{
  background: white !important;
  width: 20%;
  text-align: center;
  -webkit-animation-name: szerokosc;
          animation-name: szerokosc;
}
/*-------------TYTUŁY--CONTENT-----------------*/
.about-header{
  animation-name:e1;
  animation-duration:1s;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
  -webkit-animation-name:e1;
  -webkit-animation-duration:1s;

}
.tytul3{
  animation-name:szerokosc;
  animation-duration:1s;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
  /*animation-iteration-count:infinite;*/
  -webkit-animation-name:szerokosc;
  -webkit-animation-duration:1s;
  /*-webkit-animation-iteration-count:infinite;*/
}
/*-------------STOPKA----------------*/
.dummy{
  height: 20vh;
}
/*----------ANIMACJE--&--RESPONSYWNOŚĆ-------*/
/* ------------ANIMACJA--SZEROKOSCI------------ */
@keyframes szerokosc
{
  0% { width:10%; }
  100% { width:100%; }
}

@-webkit-keyframes szerokosc
{
  0% { width:10%; }
  100% { width:100%; }
}
@keyframes e1
{
  0% { width:300%; }
  100% { width:100%; }
}

@-webkit-keyframes e1
{
  0% { width:300%; }
  100% { width:100%; }
}
/*--------------RESPONSYWNOŚĆ----------------*/
@media screen and (max-width: 700px) {
  .firma-nav {
    display: none;
  }
}
/* ------------ANIMACJA--POJAWIANIA--SIĘ--LITER---------------*/
@-webkit-keyframes pojawianie {
    from {opacity:0;}
    to {opacity:1;}
}
@keyframes pojawianie {
    from {opacity:0;}
    to {opacity:1;}
}
/*------------ANIMACJA-----------*/


.slider{
  height:50vh!important;
  width: 100%!important;
  background-size: contain;
}
.title
{
  text-align: center;
  color:#fff;
  padding: 10vh 0 10vh 0;
  font-size: 5rem;
}
.description
{
text-align: center;
color:#fff;
padding: 0 5% 0 5%;
font-size: 1.5rem;
}

.NavBottom{
  height: 40vh;
  background-color: #727171;
  align-items: center;
  border-bottom: 2px;
  border-bottom-color: black;
}
.dummy{
  height: 25px;
}
.cardText{
  text-align: justify;
  padding: 10px;
}
.textcard h1 {
  transition: all .5s;
}
.textcard h1:hover {
  color: blue;
}
.navElement{
  text-align: center;
  padding-top: 20vh;
  transition: all .5s;
}
.navElement:hover{
  color: blue;
}
.footer{
  text-align: center;
  height: 40px;
}
.footer p{
  padding-top: 10px;
}
.cardTitle{
text-align: center;
margin-bottom: 6vh;
}

@media screen and (min-width:2001px) {
  .nav-link:first-child{
    margin-left: 15vw;
  }
  .nav-link{
    padding: 15px 150px 15px 150px !important;
  }
}

@media screen and (max-width:2000px) {
  .nav-link:first-child{
    margin-left: 15vw;
  }
  .nav-link{
  padding: 15px 100px 15px 100px !important;
  }
}

@media screen and (max-width:1499px) {
  .nav-link:first-child{
    margin-left: 10vw;
  }
  .nav-link{
padding: 15px 50px 15px 50px !important;
  }
}

@media screen and (max-width:999px) {
  .nav-link:first-child{
    margin-left: 0;
  }

  .nav-link{
padding: 15px 30px 15px 30px !important;
  }
}

.nav-link{
  transition: all 1s !important;
  transition-timing-function: cubic-bezier(0.04, 1.38, 1, 1) !important;
  text-align: center;
  display: block;
  padding:20px;
  margin:0;
}
.nav-link:hover{
  background: #858d9a !important;
}

.stopka{
  height: 300px auto;
  border-top: 2px solid white;
  padding-top: 50px;
  text-align: justify;
  padding-left: 3%;
  background: #7a7676;
  /*border-bottom: 0.5px solid black;
  border-radius: 2px;*/
}
.card-footer{
  padding-top: 50px;
}
.stopka2{
  padding-top: 20px;
  text-align: center;
}
ul{
  padding-left: 1px;
}
a:link{
  text-decoration: none;
  color: black;
}
.card1 h5{
  padding-top: 10px;
}
.element-listy{
  list-style-type: none;
}
.card-footer h5{
  transition: all .5s;
}
.card-footer h5:hover{
  color: white;
}
.Icon{
  width: 50px;
  height: 50px;
}

