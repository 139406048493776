@media screen and (min-width:2001px) {
  .nav-link:first-child{
    margin-left: 15vw;
  }
  .nav-link{
    padding: 15px 150px 15px 150px !important;
  }
}

@media screen and (max-width:2000px) {
  .nav-link:first-child{
    margin-left: 15vw;
  }
  .nav-link{
  padding: 15px 100px 15px 100px !important;
  }
}

@media screen and (max-width:1499px) {
  .nav-link:first-child{
    margin-left: 10vw;
  }
  .nav-link{
padding: 15px 50px 15px 50px !important;
  }
}

@media screen and (max-width:999px) {
  .nav-link:first-child{
    margin-left: 0;
  }

  .nav-link{
padding: 15px 30px 15px 30px !important;
  }
}
