.nav-link{
  transition: all 1s !important;
  transition-timing-function: cubic-bezier(0.04, 1.38, 1, 1) !important;
  text-align: center;
  display: block;
  padding:20px;
  margin:0;
}
.nav-link:hover{
  background: #858d9a !important;
}
