.NavBottom{
  height: 40vh;
  background-color: #727171;
  align-items: center;
  border-bottom: 2px;
  border-bottom-color: black;
}
.dummy{
  height: 25px;
}
.cardText{
  text-align: justify;
  padding: 10px;
}
.textcard h1 {
  transition: all .5s;
}
.textcard h1:hover {
  color: blue;
}
.navElement{
  text-align: center;
  padding-top: 20vh;
  transition: all .5s;
}
.navElement:hover{
  color: blue;
}
.footer{
  text-align: center;
  height: 40px;
}
.footer p{
  padding-top: 10px;
}
.cardTitle{
text-align: center;
margin-bottom: 6vh;
}
