
.slider{
  height:50vh!important;
  width: 100%!important;
  background-size: contain;
}
.title
{
  text-align: center;
  color:#fff;
  padding: 10vh 0 10vh 0;
  font-size: 5rem;
}
.description
{
text-align: center;
color:#fff;
padding: 0 5% 0 5%;
font-size: 1.5rem;
}
